import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    tabList: [], //资讯tab
    active: '', //名校详情 简介 文章
    optionsList: [],//省市
    user: {},//用户信息
    item: {},//详情
    schoolItem: {},//名校详情
    circleActive: 0,//圈子 广场 动态 对话 状态
  },
  mutations: {
    tabList(state, array) {
      state.tabList = array
    },
    active(state, id) {
      state.active = id
    },
    optionsList(state, array) {
      state.optionsList = array
    },
    user(state, obj) {
      state.user = obj
    },
    item(state, obj) {
      state.item = obj
    },
    schoolItem(state, obj) {
      state.schoolItem = obj
    },
    circleActive(state, id) {
      state.circleActive = id
    }
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedstate({
      storage: window.sessionStorage
    })
  ]
})
