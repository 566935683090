import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/lookIndex'
}, {
  path: '/login',
  name: 'login',
  component: () => import('../views/login'),
},
// 注册
{
  path: '/register',
  name: 'register',
  component: () => import('../views/register'),
},
// 找回密码
{
  path: '/findPassword',
  name: 'findPassword',
  component: () => import('../views/findPassword'),
},
{
  path: '/index',
  name: 'index', // 选课/
  component: () => import('../views/index'),
  children: [{
    path: '/lookIndex',
    name: 'lookIndex',
    component: () => import('../views/lookAt/lookIndex'),
  }, {
    path: '/periIndex',
    name: 'periIndex',
    component: () => import('../views/periphery/periIndex'),
  },
  {
    path: '/schoolIndex',
    name: 'schoolIndex',
    component: () => import('../views/school/schoolIndex'),
  },
  {
    path: '/myIndex',
    name: 'myIndex',
    component: () => import('../views/my/myIndex'),
  },
  // 圈子
  {
    path: '/circleIndex',
    name: 'circleIndex',
    component: () => import('../views/circle/circleIndex.vue'),
  },
  ]
},
// 圈子
{
  path: '/circlePage',
  name: 'circlePage',
  component: () => import('../views/lookAt/circlePage'),
},
// 看看搜索页面
{
  path: '/lookSearch',
  name: 'lookSearch',
  component: () => import('../views/lookAt/lookSearch'),
},
// 看看搜索结果页面
{
  path: '/lookResult',
  name: 'lookResult',
  component: () => import('../views/lookAt/lookResult'),
},
// 详情
{
  path: '/lookDetail',
  name: 'lookDetail',
  component: () => import('../views/lookAt/lookDetail'),
},
// 名校详情
{
  path: '/schoolDetail',
  name: 'schoolDetail',
  component: () => import('../views/school/schoolDetail'),
},
// 名校搜索
{
  path: '/schoolSearch',
  name: 'schoolSearch',
  component: () => import('../views/school/schoolSearch'),
},
// 名校搜索结果
{
  path: '/schoolResult',
  name: 'schoolResult',
  component: () => import('../views/school/schoolResult'),
},
// 编辑资料
{
  path: '/editPage',
  name: 'editPage',
  component: () => import('../views/my/editPage'),
},
// 消息中心
{
  path: '/messagePage',
  name: 'messagePage',
  component: () => import('../views/my/messageFolder/messagePage'),
},
// 消息详情
{
  path: '/messageDetail',
  name: 'messageDetail',
  component: () => import('../views/my/messageFolder/messageDetail'),
},

// 偏好管理
{
  path: '/managePage',
  name: 'managePage',
  component: () => import('../views/my/messageFolder/managePage'),
},
// 评论
{
  path: '/commentPage',
  name: 'commentPage',
  component: () => import('../views/my/messageFolder/commentPage.vue'),
},
// 点赞
{
  path: '/likePage',
  name: 'likePage',
  component: () => import('../views/my/messageFolder/likePage.vue'),
},
// 收藏
{
  path: '/collectPage',
  name: 'collectPage',
  component: () => import('../views/my/messageFolder/collectPage.vue'),
},
// 浏览记录
{
  path: '/browsePage',
  name: 'browsePage',
  component: () => import('../views/my/messageFolder/browsePage.vue'),
},
// 设置
{
  path: '/setIndex',
  name: 'setIndex',
  component: () => import('../views/my/setFolder/setIndex.vue'),
},
// 账号管理
{
  path: '/accountManage',
  name: 'accountManage',
  component: () => import('../views/my/setFolder/accountManage.vue'),
},
//更改手机号
{
  path: '/editMobile',
  name: 'editMobile',
  component: () => import('../views/my/setFolder/editMobile.vue'),
},
// 手机号更换成功
{
  path: '/mobilsuccess',
  name: 'mobilsuccess',
  component: () => import('../views/my/setFolder/mobilsuccess.vue'),
},
// 修改密码
{
  path: '/editPassword',
  name: 'editPassword',
  component: () => import('../views/my/setFolder/editPassword.vue'),
},
// 注销页面
{
  path: '/logOffPage',
  name: 'logOffPage',
  component: () => import('../views/my/setFolder/logOffPage.vue'),
},
// 隐私设置
{
  path: '/privacyPage',
  name: 'privacyPage',
  component: () => import('../views/my/setFolder/privacyPage.vue'),
},
// 黑名单
{
  path: '/blacklistPage',
  name: 'blacklistPage',
  component: () => import('../views/my/setFolder/blacklistPage.vue'),
},
// 意见反馈
{
  path: '/feedbackPage',
  name: 'feedbackPage',
  component: () => import('../views/my/setFolder/feedbackPage.vue'),
},
// 反馈详情
{
  path: '/feedbackDetail',
  name: 'feedbackDetail',
  component: () => import('../views/my/setFolder/feedbackDetail.vue'),
},
// 问题反馈
{
  path: '/problemPage',
  name: 'problemPage',
  component: () => import('../views/my/setFolder/problemPage.vue'),
},
// 我的动态
{
  path: '/trendsPage',
  name: 'trendsPage',
  component: () => import('../views/my/trendsPage.vue'),
},
// 动态详情
{
  path: '/trendsDetail',
  name: 'trendsDetail',
  component: () => import('../views/my/trendsDetail.vue'),
},
// 校长邀请
{
  path: '/invitePage',
  name: 'invitePage',
  component: () => import('../views/my/invitePage.vue'),
},
// 圈子点赞和收藏
{
  path: '/likeCollection',
  name: 'likeCollection',
  component: () => import('../views/circle/likeCollection.vue'),
},
// 圈子评论
{
  path: '/circleCommentPage',
  name: 'circleCommentPage',
  component: () => import('../views/circle/circleCommentPage.vue'),
},
// 圈子我的粉丝
{
  path: '/fansPage',
  name: 'fansPage',
  component: () => import('../views/circle/fansPage.vue'),
},
// 通讯录
{
  path: '/addressPage',
  name: 'addressPage',
  component: () => import('../views/circle/addressPage.vue'),
},
// 好友申请
{
  path: '/applyForPage',
  name: 'applyForPage',
  component: () => import('../views/circle/applyForPage.vue'),
},
// 好友申请详情
{
  path: '/applyDetail',
  name: 'applyDetail',
  component: () => import('../views/circle/applyDetail.vue'),
},
// 关注列表
{
  path: '/followPage',
  name: 'followPage',
  component: () => import('../views/circle/followPage.vue'),
},
//聊天
{
  path: '/chatPage',
  name: 'chatPage',
  component: () => import('../views/circle/chatPage.vue'),
},
// 添加好友
{
  path: '/addFriends',
  name: 'addFriends',
  component: () => import('../views/circle/addFriends.vue'),
},
// 好友动态
{
  path: '/TrendIndex',
  name: 'TrendIndex',
  component: () => import('../views/friendTrends/index.vue'),
},
// 发布动态
{
  path: '/sendPage',
  name: 'sendPage',
  component: () => import('../views/circle/sendPage.vue'),
},
// 发布动态选择城市
{
  path: '/positionPage',
  name: 'positionPage',
  component: () => import('../views/circle/positionPage.vue'),
},
// 草稿箱
{
  path: '/drafts',
  name: 'drafts',
  component: () => import('../views/circle/drafts.vue'),
},
// 添加好友
{
  path: '/seekFriend',
  name: 'seekFriend',
  component: () => import('../views/circle/seekFriend.vue'),
},
// 查找好友结果
{
  path: '/seekResult',
  name: 'seekResult',
  component: () => import('../views/circle/seekResult.vue'),
},
// 条件查找
{
  path: '/condition',
  name: 'condition',
  component: () => import('../views/circle/condition.vue'),
},
//圈子认证
{
  path: '/authentication',
  name: 'authentication',
  component: () => import('../views/circle/authentication.vue'),
},
// 圈子广场搜索
{
  path: '/squareSear',
  name: 'squareSear',
  component: () => import('../views/circle/squareSear.vue'),
},
// 圈子广场搜索结果
{
  path: '/squareResult',
  name: 'squareResult',
  component: () => import('../views/circle/squareResult.vue'),
},
// 圈子我的关注搜索结果
{
  path: '/followResult',
  name: 'followResult',
  component: () => import('../views/circle/followResult.vue'),
},

// 客服中心
{
  path: '/customer',
  name: 'customer',
  component: () => import('../views/my/customer.vue'),
},
]
const router = new VueRouter({
  routes
})

export default router
