import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
// 加载动态设置 REM 基准值
import 'amfe-flexible'
import BaiduMap from 'vue-baidu-map'

Vue.config.productionTip = false

import TencentCloudChat from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';

let options = {
  SDKAppID: 1600018907 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
let tim = TencentCloudChat.create(options); // SDK 实例通常用 chat 表示
tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
// chat.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});

Vue.prototype.Tim = TencentCloudChat;
Vue.prototype.tim = tim;


Vue.use(Vant)
import ElementUI from 'element-ui'
// import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
// import '@/utils/IMInit'
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'your_app_key' // 替换成你自己的百度地图开发密钥
})
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')


